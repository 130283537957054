import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink, useLocation } from 'react-router-dom';
import useFetch from './useFetch';

const GuideNav = ({chapters, endpoint}) => {
    const location = useLocation();
    const urlPath = location.pathname;
    const { data: readTime } = useFetch(`${endpoint}reading/`);
    const { id } = useParams();
    const { data, error, isPending } = useFetch(`${endpoint}chapters/` + id);
    
    const hideNavigation = () => {
        if (document.body.classList.contains('nav-open')) {    
            document.body.classList.remove('nav-open');
        }
    }
    
    function groupBy(d, key) {
      return d.reduce((acc, x) => {
        acc[x[key]] = [...(acc[x[key]] || []), x];
        return acc;
      }, {});
    }
    
    let chapterSections;
    const groupChapters = () => {
      chapterSections = groupBy(chapters, ['section']);
    }
    
    const applyActiveHeading = () => {
        const chapterGroup = document.querySelectorAll('.chapter_group');
        chapterGroup.forEach((group) => {
            const activeLink = group.querySelector('.active_chapter');
            if (activeLink) {
                group.classList.add('active');
            } else {
                if (group.classList.contains('active')) {
                  group.classList.remove('active');  
                }
            }
        });
    }
    
    useEffect(() => {
       applyActiveHeading();    
    }, [chapters, location.pathname]);
    
    return (
        <nav className="guide-nav">
           { error && <div>{ error }</div> }
           { chapters && (
            <>
            <div className="guide-nav__inner">
                <div className="guide-nav__header">
                    <p className="guide-nav__time">{readTime && `${readTime.time} minutes read time` }</p>
                    <button type="button" className="close_btn" aria-label="Close Menu" onClick={hideNavigation}></button>
                </div>
                
                <nav className="guide-nav__nav">
                    <ul>
                        { groupChapters() }
                        { Object.entries(chapterSections).map(([parentChapter, childChapters]) => {
                          return (
                            <li className='chapter_group' key={parentChapter}>
                              {childChapters.map(chapter => {
                                return (
                                <NavLink
                                    key={chapter.id} 
                                    to={`${endpoint}chapters/${chapter.id}`}
                                    className={chapter.is_section_heading ? 'section_heading' : 'not_heading'}
                                    activeClassName='active_chapter'
                                    onClick={hideNavigation}
                                    >{chapter.is_section_heading ? chapter.section_heading : null}
                                </NavLink>
                                )
                              })}
                            </li>
                          )
                        })}
                    </ul>
                </nav>
            </div>
            </>
            )}
        </nav>
    );
}

export default GuideNav;