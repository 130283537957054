import { Link, useHistory, useLocation } from 'react-router-dom';

const FooterNav = ({endpoint}) => {
    const history = useHistory();
    const location = useLocation();
    endpoint = location.pathname;
    
    function ShowHelp() {
      const location = useLocation();
      const urlPath = location.pathname;
      const chapterHelp = urlPath.replace(/chapters.*$/,'');

      if (!urlPath.includes('contact')) {
        return <Link to="/contact" className="mobile-footer__help">Help</Link>
      }
    }
    return (
        <footer className="mobile-footer">
            <nav className="mobile-footer__nav">
                <button className="mobile-footer__back" onClick={() => history.goBack()}>Previous</button>

                <Link to="/" className="mobile-footer__home">Home</Link>

                {ShowHelp()}
            </nav>
        </footer>
    );
}

export default FooterNav;