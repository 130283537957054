import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import GuideNav from './GuideNav';
import GuideChapter from './GuideChapter';
import useFetch from './useFetch';

const GuideContent = ({endpoint}) => {
    const location = useLocation();
    const guideBase = location.pathname.replace(/chapters.*$/,'');
    endpoint = guideBase;
    const chaptersBase = location.pathname.replace(/[^\/]+$/,''); /* eslint-disable-line */
    const { data: chapters } = useFetch(chaptersBase);
    
    return (
        <section className="guide-content">
            <GuideNav chapters={chapters} endpoint={endpoint}/>
            <GuideChapter chapters={chapters} endpoint={endpoint}/>
        </section>
    );
}

export default GuideContent;