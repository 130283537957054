import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './scss/base/_reset.scss';
import './scss/base/_base.scss';
import './scss/base/_helpers.scss';
import './scss/base/_images.scss';
import './scss/base/_typography.scss';
import './scss/components/_buttons.scss';
import './scss/components/_modal.scss';
import './scss/components/_age-gate.scss';
import './scss/components/_carousel-modal.scss';
import './scss/components/_navigation.scss';
import './scss/components/_page-header.scss';
import './scss/components/_select-setup.scss';
import './scss/components/_guide-nav.scss';
import './scss/components/_guide-step.scss';
import './scss/components/_guide-help.scss';
import './scss/components/_save-guide.scss';
import './scss/components/_footer-nav.scss';
import 'swiper/swiper-bundle.css';
import './scss/layout/_footer.scss';
import './scss/layout/_header.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
