import { Link, useHistory, useLocation } from 'react-router-dom';

const Header = () => {
    const history = useHistory();
   
    const showNavigation = () => {
      document.body.classList.add('nav-open');
    }
    
    function HideNavigation() {
      const location = useLocation();
      const urlPath = location.pathname;
      if (urlPath.includes('chapters')) {
        return <button type="button" className="site-header__nav-button" aria-label="Open Menu" onClick={showNavigation}></button>
      }
    }

    return (
        <header className="site-header">
            <div className="site-header__inner">
                <button type="button" className="site-header__back-button" onClick={() => history.goBack()}>Back</button>

                {HideNavigation()}

                <Link to="/" className="site-header__logo">
                    <span>Camden Town Brewery</span>
                </Link>

                <Link to="/contact" className="site-header__contact-button">Get In Contact</Link>
            </div>
        </header>
    );
}

export default Header;