import cellarIcon from './assets/images/icon_cellar.png';
import gardenBarAndUndercounterIcon from './assets/images/icon_garden_bar_undercounter.png';

const Home = () => {

    return (
        <section className="select-setup">
            <header className="page-header">
                <h1 className="page-header__title">Choose Setup</h1>
                <p className="page-header__summary">Who's ready for some fresh beer line cleaning? Please select the setup which matches your installation to read our handy guides.</p>
            </header>
            <ul className="select-setup__list">
                <li>
                    <a href="/guide/cellar/chapters/1">
                        <img src={cellarIcon} alt="Cellar"/>
                        <h2>Cellar</h2>
                    </a>
                </li>
                <li>
                    <a href="/guide/ucgb">
                        <img src={gardenBarAndUndercounterIcon} alt="Undercounter & Garden Bar"/>
                        <h2>Garden Bar & Undercounter</h2>
                    </a>
                </li>
            </ul>
        </section>
    );
}

export default Home;
