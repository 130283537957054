import { Link, useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import useFetch from './useFetch';
import Carousel from './Carousel';

const GuideChapter = ({chapters, endpoint}) => {
    const location = useLocation();
    const urlPath = location.pathname;
    const { id } = useParams();
    const { data, error, isPending } = useFetch(`${endpoint}chapters/` + id);
    
    let nextChapter;
    const handleDoneClick = () => {
        nextChapter = data.id + 1;

        if (nextChapter > chapters.length) {
            nextChapter = 'guide-complete';
        }
    }

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(min-width: 769px)' });

    return (
        <article className="guide-step">
           { isPending && <h1>Loading...</h1> }
           { error && <div>{ error }</div> }
           { data && (
            <>

            <div className="guide-step__col">
                <h1 className="guide-step__title">{data.title}</h1>

                {isMobile && <Carousel data={data} />}
            

                <p className="guide-step__desc">
                    { data.body.split("\n").map(function(text, idx) {
                        return (
                            <span key={idx}>
                                {text}
                                <br/>
                            </span>
                        )
                    })}
                </p>

                <div className="cta-wrapper">
                    {chapters && handleDoneClick()}
                    <Link className="button--alternative" to={`${endpoint}chapters/${nextChapter}`}>Next</Link>

                    { data.help ? (
                    <Link className="button--secondary" to={`${endpoint}help/${data.id}`}>I need help</Link>
                    ) : (null) }
                </div>
            </div>

            <div className="guide-step__col desktop-only">
                {isTablet && <Carousel data={data} />}
            </div>
            </>
          )}
        </article>
    );
}

export default GuideChapter;
