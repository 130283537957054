import { useState, useRef, useEffect } from 'react';
import { Navigation, Pagination, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

const Carousel = (props) => {
    const [modalState, setModalState] = useState(false);
    const [swiper, setSwiper] = useState(null);
    const gallery = useRef(null);
    const gallerySwiper = useRef(null);
    const closeGallery = useRef(null);
    const {images, title} = props.data;

    const openModal = () => {
        if (!modalState) {
            setModalState(true);
            gallery.current.classList.remove('carousel-modal-closed');
            gallery.current.classList.add('carousel-modal-open');
        }
    }

    const closeModal = () => {
        if (modalState) {
            setModalState(false);
            gallery.current.classList.remove('carousel-modal-open');
            gallery.current.classList.add('carousel-modal-closed');
            
            if (swiper) {
                swiper.slideTo(0);
            }
        }
    }
    
    const isMultipleImgs = () => {
        if (swiper && swiper.slides.length > 1) {
            gallery.current.classList.add('multiple_images');
        } 
        
        if (swiper && swiper.slides.length <= 1) {
            gallery.current.classList.remove('multiple_images'); 
        }
    }
    
    const escFunction = (event) => {
        if (event.keyCode === 27) {
            closeModal();
        }
    }
    
    useEffect(() => {
        document.addEventListener('keydown', escFunction);
        return () => {
            document.removeEventListener('keydown', escFunction);
        };
    });

    return (
        <div className="guide-images carousel-modal-closed" ref={gallery}>
            <div className="guide-images__wrapper">

                <button type="button" className="close_btn" aria-label="Close" ref={closeGallery} onClick={closeModal}></button>

                { images && (
                <>
                <Swiper
                  modules={[Navigation, Pagination, Keyboard]}
                  slidesPerView="auto"
                  centeredSlides
                  navigation
                  observer
                  pagination={{ clickable: true }}
                  keyboard={{ enabled: true }}
                  onSwiper={(s) => {
                    setSwiper(s);
                  }}
                  onObserverUpdate={() => {
                    isMultipleImgs();   
                  }}
                  ref={gallerySwiper}
                  onClick={openModal}
                >
                  {images.map((img) => (
                    <SwiperSlide key={img}>
                        <img src={img} alt={title} />
                    </SwiperSlide>
                    ))}
                </Swiper>
                </>
                )}
            </div>
        </div>
    );
}

export default Carousel;