import { Link, useLocation } from 'react-router-dom';
import oneProduct from './assets/images/one_product.png';
import twoProduct from './assets/images/two_product.png';

const NumberProducts = () => {
    const location = useLocation();
    const urlPath = location.pathname;

    return (
        <section className="select-setup">
            <header className="page-header">
                <h1 className="page-header__title">Select number of products</h1>
                <p className="page-header__summary">How many Camden beers are you dispensing with your setup?</p>
            </header>
            <ul className="select-setup__list">
                <li>
                    <Link to={`${urlPath}/oneproduct/chapters/1`}>
                        <img src={oneProduct} alt="1 Product"/>
                        <h2>1 Product</h2>
                    </Link>
                </li>
                <li>
                    <Link to={`${urlPath}/twoproduct/chapters/1`}>
                        <img src={twoProduct} alt="2 Product"/>
                        <h2>2 Product</h2>
                    </Link>
                </li>
            </ul>
        </section>
    );
}

export default NumberProducts;
