import drinkaware from './assets/images/drinkaware.svg';

const Footer = () => {
     return (
        <footer className="site-footer-dpc site-footer">
            <div className="site-footer-dpc__inner">
              <div className="flex__content">
                   <div className="nav-wrapper">
                        <nav className="footer-nav">
                           <p className="nav__title">Information</p>
                            <ul className="footer-nav__list">
                                <li>
                                    <a href="https://www.camdentownbrewery.com/terms/website-terms-and-conditions/" rel="noreferrer">Terms &amp; Conditions</a>
                                </li>
                                <li>
                                    <a href="https://www.camdentownbrewery.com/terms/privacy-policy/" rel="noreferrer">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="https://www.camdentownbrewery.com/terms/cookie-policy/" rel="noreferrer">Cookie Policy</a>
                                </li>
                                <li>
                                    {/* eslint-disable-next-line */}
                                    <a href="#" className="optanon-toggle-display">Cookie Settings</a>
                                </li>
                                <li>
                                    <a href="https://www.camdentownbrewery.com/modern-slavery-statement/" rel="noreferrer">Modern Slavery Statement</a>
                                </li>
                            </ul>
                        </nav>
                        <nav className="social-nav">
                           <p className="social__title">Follow Us</p>
                            <ul className="social-nav__list">
                                <li>
                                    <a href="https://www.facebook.com/camdenbrewery?utm_source=camdentownbrewery&amp;utm_medium=ontrade&amp;utm_campaign=follow_us" className="facebook" target="_blank" rel="noreferrer">
                                        <span>Facebook</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/camdentownbrewery?utm_source=camdentownbrewery&amp;utm_medium=ontrade&amp;utm_campaign=follow_us" className="instagram" target="_blank" rel="noreferrer">
                                        <span>Instagram</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/camdenbrewery?utm_source=camdentownbrewery&amp;utm_medium=ontrade&amp;utm_campaign=follow_us" className="twitter" target="_blank" rel="noreferrer">
                                        <span>Twitter</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCASfbSs99KllYW6RWC1DlfA?utm_source=camdentownbrewery&amp;utm_medium=ontrade&amp;utm_campaign=follow_us" className="youtube" target="_blank" rel="noreferrer">
                                        <span>YouTube</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="drinkaware">
                        <p className="drinkaware__title">With great beer comes great responsibility</p>

                          <p className="drinkaware__message">Please enjoy responsibly and don’t share or forward this website or its contents to anyone under 18. Camden Town Brewery is not responsible for the content of external sites.</p>
                          <p className="drinkaware__link"><a href="https://www.drinkaware.co.uk/?utm_source=camdentownbrewery&amp;utm_medium=ontrade&amp;utm_campaign=responsible_drinking_message" target="_blank" rel="noreferrer"><img src={drinkaware} alt="drinkaware.co.uk"/></a> for the facts</p>

                    </div>
                </div>
                <p className="footer__copyright">© 2022 AB InBev UK Limited. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
