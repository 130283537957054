import React from 'react';
import logo from './assets/images/logo-full.svg';
import pacman from './assets/images/pacman.svg';
import drinkAware from './assets/images/drinkaware.svg';

class AgeGate extends React.Component {
    constructor() {
        super();
        this.acceptButton = React.createRef();
        this.closeAgeGate = this.closeAgeGate.bind(this);
        this.state = { visibility: '' }
    }

    setCookie(name, value) {
        document.cookie = name + '=' + value + ';path=/;expires=Fri, 31 Dec 9999 23:59:59 GMT';
    }

    getCookie(name) {
        const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    checkForCookie() {
      if (this.getCookie('age-gate') === 'true') {
        this.setState({
           visibility: 'hidden'
        });
      }
    }

    closeAgeGate() {
      if (this.getCookie('age-gate') === null) {
        this.setCookie('age-gate', 'true');
      }
      this.checkForCookie();
    }

    componentDidMount() {
        this.checkForCookie();
    }

    render() {
        return (
            <div className={this.state.visibility + ' age-gate-dpc user-message'} data-cookie-id="age-gate">
                <div className="user-message__inner">
                    <img src={logo} alt="Camden Town Brewery" className="user-message__logo" />
                    <p className="user-message__title">How young are you?</p>
                    <p className="user-message__message">
                        Or in other words, are you of legal drinking age? We have to ask because it’s the law. Just so you know.
                    </p>
                    <div className="user-message__button-wrapper">
                        <button className="user-message__button button--primary" ref={this.acceptButton} onClick={this.closeAgeGate}>Yep, I’m over 18, let me in.</button>
                    </div>
                    <a href="https://www.google.co.uk/" className="user-message__link">
                        <img src={pacman} alt="Pacman" />
                        <span>Nope, I’m not quite there yet.</span>
                    </a>
                    <div className="drinkaware">
                        <p className="drinkaware__title">With great beer comes great responsibility</p>
                        <p className="drinkaware__message">Please enjoy Camden Town Brewery responsibly and don’t share or forward this website or its contents to anyone under 18.</p>
                        <p className="drinkaware__link"><a href="https://www.drinkaware.co.uk/?utm_source=camdentownbrewery&utm_medium=ontrade&utm_campaign=responsible_drinking_message"><img src={drinkAware} alt="drinkaware.co.uk"/></a> for the facts</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default AgeGate;
