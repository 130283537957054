import { useState, useEffect } from 'react';

const useFetch = (endpoint) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
      const abortCont = new AbortController();
      fetch(`https://api.camdentownbrewery.com${endpoint}`, {signal: abortCont.signal})
      .then(res => {
        if (!res.ok) {
          throw Error('could not fetch the data for that resource. ' + res.status);
        }
        return res.json();
      })
      .then(data => {
        setIsPending(false);
        setData(data);
        setError(null);
      })
      .catch(err => {
        if (!err.name === 'AbortError') {
            console.log('fetch aborted');
        } else { 
            setIsPending(false);
            setError(err.message);
        }
      });
      
      return () => abortCont.abort();  
  }, [endpoint])

  return { data, isPending, error };
}

export default useFetch;
