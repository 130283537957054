import contactImg1 from './assets/images/contact1.jpg';
import contactImg2 from './assets/images/contact2.jpg';
import contactImg3 from './assets/images/contact3.jpg';

import { useMediaQuery } from 'react-responsive'
import Carousel from './Carousel';

const Contact = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(min-width: 769px)' });
    const data = {
        "images":
            [
                contactImg1,
                contactImg2,
                contactImg3
            ]
    }
    return (
        <section className="guide-step guide-step__help">
           <div className="guide-help__inner">
               <div className="guide-step__col">
                    <h3 className="guide-step__index">Get In Touch</h3>
                    <h1 className="guide-step__title">Having trouble? Get in touch!</h1>

                    {isMobile && <Carousel data={data} />}

                    <p>Click the button below to send us an email. Please include the following and make sure that no individuals appear on the photos:</p>
                    <ol>
                        <li>A brief description of the issue you are having</li>
                        <li>A clean photo of your cellar board whilst cleaning - example 1 in gallery.</li>
                        <li>A clear photo of your cleaning bottle and pump whilst cleaning - example 2 in gallery.</li>
                       <li>A clear photo of your gas bottles and regulars - example 3 in gallery.</li>
                    </ol>
                    <div className="cta-wrapper">
                        <a className="button--secondary" href="mailto:beertech@camdentownbrewery.com?subject=LINE CLEAN GUIDE HELP">Send us an email</a>
                    </div>
                </div>
                <div className="guide-step__col desktop-only">
                    {isTablet && <Carousel data={data} />}
               </div>
            </div>
        </section>
    );
}

export default Contact;
