import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <section class="page-not-found">
            <header className="page-header">
                <h1 className="page-header__title">Page Not Found</h1>
                <p className="page-header__summary"><Link to="/">Back to Home</Link></p>
            </header>
        </section>
    );
}

export default NotFound;