import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import useFetch from './useFetch';
import Carousel from './Carousel';

const Help = () => {
    const location = useLocation();
    const pathArray = location.pathname.split('/');
    var pathToApi;

    if (pathArray[3] === 'help') {
      // pathToApi = location.pathname;
      // eslint-disable-next-line    
      pathToApi = '/' + pathArray[1] + '/' + pathArray[2] + '/' + '/chapters/' + pathArray[4] + '/' + pathArray[3];
    } else {
      pathToApi = '/' + pathArray[1] + '/' + pathArray[2] + '/' + pathArray[3] + '/chapters/' + pathArray[5] + '/' + pathArray[4];
    }

    const { data, error, isPending } = useFetch(`${pathToApi}`);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(min-width: 769px)' });
    
    return (
        <section className="guide-step guide-step__help">
          { isPending && <h1>Loading...</h1> }
          { error && <div>{ error }</div> }
          { data && (
           <div className="guide-help__inner">
               <div className="guide-step__col">
                    <h3 className="guide-step__index">Help</h3>
                    <h1 className="guide-step__title">{data.title}</h1>

                    {isMobile && <Carousel data={data} />}

                    { data.body.split("\n").map(function(text, idx) {
                        return (
                            <p key={idx}>
                                {text}
                                <br/>
                            </p>
                        )
                    })}
                </div>
                <div className="guide-step__col desktop-only">
                    {isTablet && <Carousel data={data} />}
               </div>
            </div>
            )}
        </section>
    );
}

export default Help;
