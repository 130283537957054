import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AgeGate from './AgeGate';
import Header from './Header';
import FooterNav from './FooterNav';
import Footer from './Footer';
import Home from './Home';
import NumberProducts from './NumberProducts';
import GuideContent from './GuideContent';
import Contact from './Contact';
import Help from './Help';
import NotFound from './NotFound';
import CautionModal from './CautionModal';

function App() {
  const [endpoint, setEndpoint] = useState(null);

  return (
    <Router>
        <div className="App">
            <main className="container">
                <Header />
                <section className="page-content">
                    <Switch>
                        <Route exact path="/">
                            <Home />
                            <CautionModal />
                            <AgeGate />
                        </Route>

                        <Route exact path={["/guide/cellar", "/guide/ucgb"]}>
                            <NumberProducts />
                        </Route>

                        <Route path={["/guide/*/chapters/:id"]}>
                            <GuideContent endpoint={endpoint}/>
                        </Route>

                        <Route path="/contact">
                            <Contact />
                        </Route>

                        <Route path="/guide/*/help/:id">
                            <Help endpoint={endpoint}/>
                        </Route>

                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </section>
                <Route path={["/guide", "/contact"]}>
                    <FooterNav endpoint={endpoint}/>
                </Route>
                <Footer />
            </main>
        </div>
    </Router>
  );
}

export default App;
