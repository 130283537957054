import React from 'react';
import cautionIcon from './assets/images/icon_caution.svg';

class CautionModal extends React.Component {
    constructor() {
        super();
        this.closeModal = this.closeModal.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.state = JSON.parse(window.sessionStorage.getItem('modalState')) || {
            modalClass: 'modal_open'
        }
    }
    
    setState(state) {
        window.sessionStorage.setItem('modalState', JSON.stringify(state));
        super.setState(state);
    }
    
    escFunction(event){
        if (event.keyCode === 27) {
            this.closeModal();
        }
    }
    
    closeModal() {
        this.setState({
           modalClass: 'closed'
        });
    }
    
    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
    }
    
    componentWillUnmount(){
        document.removeEventListener('keydown', this.escFunction, false);
    }

    render() {
        return (
            <div className={this.state.modalClass + ' modal modal_caution'}>
                <div className="modal_inner">
                    <button type="button" className="close_btn" aria-label="Close" onClick={this.closeModal}></button>
                    <div className="modal_content">
                        <img src={cautionIcon} alt="Caution"/>
                        <h2>Before you begin</h2>
                        <p>Appropriate PPE needs to be worn</p>
                        <p>The guide should only be used solo after an initial technician-led session.</p>
                    </div>
                </div>
            </div>    
        );
    }
}

export default CautionModal;